import * as React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { withSubtheme } from "../../../StarberryComponentsMui"

import ComponentLayout from "../ComponentLayout"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import defaults from "./defaults"

function LinearProgressWithLabel(props) {
    const {
        progressValueTypographyProps,
        progressValueContainerProps,
        progressValueContainerProgressProps,
        progressValueContainerNumberProps,
        variant,
        value,
        color,
    } = props

    return (
        <Box sx={progressValueContainerProps}>
            <Box sx={progressValueContainerProgressProps}>
                <LinearProgress
                    variant={variant}
                    value={value}
                    classes={{
                        root: "progressComponent-root",
                        colorPrimary: "progressComponent-colorPrimary",
                        bar: "progressComponent-bar",
                    }}
                    sx={{
                        "& .progressComponent-bar": {
                            backgroundColor: color,
                        },
                    }}
                />
            </Box>
            <Box sx={progressValueContainerNumberProps}>
                <Typography
                    {...progressValueTypographyProps}
                    classes={{ root: "progressValueTypography" }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
}

// MIN = Minimum expected value
// MAX = Maximium expected value
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value, max) => (value * 100) / max

const FeedbackProgress = withSubtheme(
    props => {
        const {
            className,
            theme,
            title,
            moreLabel,
            moreLinkUrl,
            titleLinkMoreTooltipProps,

            progressContainerProps,
            progressComponentProps,
            pendingContainerProps,
            pendingTextTitleProps,
            pendingTextStatusProps,
            count,
            data,
            _data,
        } = props

        const feedbackData = data ? data : _data || []

        const sortedFeedbackData = _.orderBy(feedbackData, ["count"], ["desc"])

        const colorCode = srt => {
            switch (srt.toLowerCase()) {
                case "too much work required":
                    return "#7552B9" // F004
                case "price too high":
                    return "#B252B9" // F005
                case "unsuitable location":
                    return "#52B992" // F002
                case "offer made on other property":
                    return "#B9527B" // F006
                case "possible interest":
                    return "#7552B9" // F001
                case "viewing cancelled":
                    return "#B95252" // F007
                case "not right size":
                    return "#5296B9" // F003
                case "unable to contact":
                    return "#520909" // F003
                default:
                    return "#000000"
            }
        }

        if (!sortedFeedbackData.length) return <></>

        return (
            <ComponentLayout
                className={className}
                theme={theme}
                title={title}
                moreLabel={moreLabel}
                moreLinkUrl={moreLinkUrl}
                titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            >
                {sortedFeedbackData.map((progress, index) => {
                    return (
                        <>
                            {progress.title && (
                                <>
                                    <div
                                        className={"progressContainer"}
                                        {...progressContainerProps}
                                    >
                                        <LinearProgressWithLabel
                                            value={normalise(
                                                progress.count,
                                                count
                                            )}
                                            color={colorCode(progress.title)}
                                            {...progressComponentProps}
                                        />
                                    </div>
                                    <Box
                                        key={progress.id}
                                        sx={pendingContainerProps}
                                    >
                                        <Typography
                                            {...pendingTextTitleProps}
                                            classes={{
                                                root: "pendingTextTitle",
                                            }}
                                        >
                                            {progress.title}
                                        </Typography>
                                        <Typography
                                            {...pendingTextStatusProps}
                                            classes={{
                                                root: "pendingTextStatus",
                                            }}
                                        >
                                            {progress.count}/{count}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </>
                    )
                })}
            </ComponentLayout>
        )
    },
    "feedbackProgress",
    defaults
)

export default FeedbackProgress
