import * as React from "react"
import Actions from "../actions"
import NoActions from "../progress/NoActions"
import { usePropertyActionState, useAuthState } from "../../../services"
import ComponentLayout from "../ComponentLayout"
import ActionsSkeletonWithMore from "../actions/actionsSkeletonWithMore"
import { ComponentWithPagination } from "../pagination"
import {
    propertyApi,
    useGetPropertyPendingActionQuery,
} from "../../../redux/services/property"
import store from "../../../redux/store"

const PendingActionsDetails = props => {
    const {
        componentLayout,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        property,
        noActionTitle,
        noActionMessage,
        noActionIcon,
        // data,
    } = props

    const {
        data: pendingActions,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyPendingActionQuery({
        property_id: property.property_detail.crm_id,
    })

    const actionData = pendingActions || []
    const noRecord = !isLoading && !actionData.length
    const propertyImage = property.property_detail?.thumbnail

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                "PendingActionCount",
                "PropertyPendingAction",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !actionData.length) {
        return <ActionsSkeletonWithMore />
    }

    if (noRecord) {
        return (
            <NoActions
                title={
                    noActionTitle
                        ? noActionTitle
                        : "There are no pending actions"
                }
                // message={noActionMessage ? noActionMessage : "At the moment all the actions are closed..."}
                icon={noActionIcon ? noActionIcon : "noPendingActions"}
            />
        )
    }

    if (componentLayout) {
        const hasData = !!actionData.length
        return (
            <ComponentLayout
                title={title}
                moreLabel={hasData ? moreLabel : ""}
                moreLinkUrl={moreLinkUrl}
                titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            >
                <ComponentWithPagination
                    component={Actions}
                    property={property.property_detail}
                    reloadDataFun={reloadDataFun}
                    data={actionData}
                    image={propertyImage}
                />
            </ComponentLayout>
        )
    } else {
        return (
            <ComponentWithPagination
                component={Actions}
                property={property.property_detail}
                reloadDataFun={reloadDataFun}
                data={actionData}
                image={propertyImage}
            />
        )
    }
}

export default React.memo(PendingActionsDetails)
