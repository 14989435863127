import * as React from "react"
import Activities from "../activities"
import NoActions from "../progress/NoActions"
import ComponentLayout from "../ComponentLayout"
import Alert from "../alert"
import RecentActivitiesSkeleton from "./recentActivitiesSkeleton"
import { ComponentWithPagination } from "../pagination"
import { useGetPropertyRecentActivityQuery } from "../../../redux/services/property"
import { isAuthenticated } from "../../../services/store/utils"
import { collectResponseMessage } from "../../../redux/utils"

const RecentActivityDetails = props => {
    const authUser = isAuthenticated()
    const {
        componentLayout,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        property,
        noActionTitle,
        noActionIcon,
        // data,
        // ...rest
    } = props

    const {
        data: recentActivities,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyRecentActivityQuery(
        {
            property_id: property.property_detail.crm_id,
        },
        { skip: !authUser }
    )

    const data = recentActivities || []
    const noRecord = !isLoading && !data.length

    if (isLoading) {
        return <RecentActivitiesSkeleton />
    }

    if (isError) {
        const errorMsg = collectResponseMessage(data, error, isError, "")
        return <Alert type={`alert`} {...errorMsg} />
    }

    if (noRecord) {
        return (
            <NoActions
                title={
                    noActionTitle
                        ? noActionTitle
                        : "There are no recent activities"
                }
                icon={noActionIcon ? noActionIcon : "recentActivitiesIcon"}
            />
        )
    }

    if (componentLayout) {
        const hasData = !!data.length
        return (
            <ComponentLayout
                title={title}
                moreLabel={hasData ? moreLabel : ""}
                moreLinkUrl={moreLinkUrl}
                titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
            >
                <ComponentWithPagination component={Activities} data={data} />
            </ComponentLayout>
        )
    } else {
        return <ComponentWithPagination component={Activities} data={data} />
    }
}

export default React.memo(RecentActivityDetails)
