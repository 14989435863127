import React from "react"
import {
    usePropertyActionState,
    useAuthState,
} from "../../../../../../services"
import CircularProgress from "../../../../progress/CircularProgress"
import NoActions from "../../../../progress/NoActions"
import Grid from "@mui/material/Grid"
import Actions from "../../../../actions"
import Calendar from "../../../../calendar"
import {
    /* calendarDate,*/ getMonth,
    dateRange,
} from "../../../../helper/dateformat"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import Skeleton from "@mui/material/Skeleton"
import {
    useGetMyPropertyQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "./../../../../../../services/store/utils"

// Calendar Action Container wrap for enable loading
const ActionDataContainer = ({ props, state, date, month, ...rest }) => {
    const data = state?.propertyAppointments || []
    const monthName = getMonth(month ? new Date(month) : new Date())

    if (state.loading_actions) {
        return <CircularProgress />
    }

    if (!state.loading_actions && !data.length) {
        return (
            <NoActions
                title="There are no appointments"
                message="At the moment all the actions are closed..."
                icon="noPendingActions"
            />
        )
    }

    return (
        <Actions
            title={monthName}
            {...props}
            data={props?.data ? props.data : data}
            {...rest}
        />
    )
}

const AppointmentsContent = props => {
    const { state, services } = usePropertyActionState()
    const { services: authServices } = useAuthState()
    const data = state?.propertyAppointments || []
    const propertyImage = props.property.property_detail?.thumbnail
    const [date, setDate] = React.useState()
    const [month, setMonth] = React.useState()

    const getAvailableDate = React.useCallback(() => {
        // collect available dates
        let availableDate = {}
        let availableDateFinal = {}

        // For testing data purposes using propertyAppointments
        if (data.length) {
            if (state.propertyAppointments) {
                Object.entries(state.propertyAppointments).map(
                    ([key, value]) => {
                        if (typeof value.value === "string") {
                            const calDate = new Date(value.value)
                            const calDateMonth = calDate.getMonth()
                            const calDateDay = calDate.getDate()
                            if (availableDate.hasOwnProperty(calDateMonth)) {
                                availableDate[calDateMonth].push(calDateDay)
                            } else {
                                availableDate[calDateMonth] = [
                                    /*new Date().getDate(),*/ calDateDay,
                                ]
                            }
                        }
                        return availableDate
                    }
                )

                // do sorting
                Object.entries(availableDate).map(([key, value]) => {
                    availableDateFinal[key] = value.sort()
                    return availableDateFinal
                })
            }
        }
        return availableDateFinal
    }, [date, state?.propertyAppointments]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = () => {
        services.getPropertyAppointments({
            property_id: props.property.property_detail.crm_id,
            /*actionDate: calendarDate(date).toString(), */ ...dateRange(
                month ? new Date(month) : new Date()
            ),
        })
    }

    const reloadDataFun = React.useCallback(() => {
        fetchData()
        store.dispatch(propertyApi.util.invalidateTags(["PendingActionCount"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData()
    }, [date, month]) // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading /* && !data.length*/) {
        return (
            <Grid container spacing={6}>
                <Grid item xs={12} lg={8}>
                    <ActionsSkeletonWithMore />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Skeleton variant="rectangular" width="100%" height={400} />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={6} xl={7}>
                <ActionDataContainer
                    date={date}
                    month={month}
                    props={props}
                    state={state}
                    reloadDataFun={reloadDataFun}
                    image={propertyImage}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
                <Calendar
                    date={date}
                    setDate={setDate}
                    getAvailableDate={getAvailableDate}
                    month={month}
                    setMonth={setMonth}
                />
            </Grid>
        </Grid>
    )
}

export default React.memo(AppointmentsContent)
