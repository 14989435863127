import React from "react"
import { get } from "lodash"
// import NoActions from "../../../../progress/NoActions";
// import Actions from '../../../../actions';
import CreateTenancy from "../../../../PropertyResult/CreateTenancy"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import ListItem from "./list"
import {
    useGetPropertyTenanciesQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"

const TenancyContent = props => {
    const authUser = isAuthenticated()
    const {
        data: propertyTenances,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyTenanciesQuery(
        { property_id: props.property.property_detail.crm_id },
        { skip: !authUser }
    )

    const offerData = propertyTenances || []
    const noRecord = !isLoading && !offerData.length
    const actionTitle = props?.offer?.actionTitle || "Tenancies"
    const propertyImage = props.property.property_detail?.thumbnail
    const showOfferBtn = get(
        props,
        `${props.role || ""}.tabOfferOfferBtn`,
        false
    )
    const showAddIcon = get(props, `${props.role || ""}.showAddIcon`, false)

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                // "PendingActionCount",
                "PropertyTenancies",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <ActionsSkeletonWithMore title />
    }

    return (
        <>
            {showOfferBtn && (
                <CreateTenancy
                    showAddIcon={showAddIcon}
                    property={props.property.property_detail}
                    reloadDataFun={reloadDataFun}
                />
            )}

            <ListItem
                noRecord={noRecord}
                data={offerData}
                image={propertyImage}
                title={actionTitle}
                // title="Tenancies"
                reloadDataFun={reloadDataFun}
            />
        </>
    )
}

export default TenancyContent
