import React, { useState, useRef } from "react"
import { isEmpty } from "lodash"
import { useReactToPrint } from "react-to-print"
import Grid from "@mui/material/Grid"
import _properties from "../../../../../../sample/properties.yaml"
import Stack from "@mui/material/Stack"
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import { Typography } from "@mui/material"
import PrintIcon from "@mui/icons-material/Print"

// table
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import {
    getTransactionStatement,
    getLandlordProperties,
} from "../../../../../../services/store/api"

// form
import FormDateField from "../../../../forms/FormDateField"
import SelectField from "../../../../forms/SelectField"

import {
    displayData,
    rawDatePickerFormat,
    reportDate,
} from "../../../../helper/dateformat"
import { formatFlotPrice } from "../../../../helper/action"

import "./style.css"
import Logo from "../../../../Logo"
import { FRONTEND_SITE_URL } from "../../../../../../constants/urls"
import CircularProgress from "../../../../progress/CircularProgress"

const addMinus = (amt, type) => {
    if (["Payments", "Income", "Receipts", "Credit Notes"].includes(type)) return amt
    return amt * -1
}

const collectPropertyAddress = (data, pid=false) => {
    console.log('first =>>>', data, pid)
    let str = []
    ;(data || []).map(p => {
        let key = Object.keys(p)[0]
        let value = p[key];
        value = value.split(',')[0]
        if (pid == 'All') {
            str.push(value)
        } else {
            if (pid && key == pid)
                str.push(value)
        }

    })

    return str.join(", ")
}

const collectAddress = data => {
    let addr = []

    if (data?.buildingNumber || data?.line1) {
        addr.push(`${data.buildingNumber || ""} ${data.line1 || ""}`)
    }
    if (data?.line2) {
        addr.push(data.line2)
    }
    if (data?.line3) {
        addr.push(data.line3)
    }
    if (data?.postcode) {
        addr.push(data.postcode)
    }

    return addr.join("<br />")
}
const LoadingContainer = () => {
    return (
        <Grid container>
            <Grid item md={8}>
                <TabContainerSkeleton noBorder>
                    <TabSkeleton />
                    <TabSkeleton />
                </TabContainerSkeleton>
            </Grid>
            <Grid item md={4}>
                <Stack direction="column" spacing={4}>
                    <AutoHeightSkeleton
                        width="100%"
                        aspectRatio={6 / 19}
                        variant="rectangular"
                    />
                    <BodyLargeSkeleton width="50%" />
                </Stack>
            </Grid>
        </Grid>
    )
}

const fieldToTotalField = (key) => {
    const fieldSet = {
        'net_amount': 'net_total',
        'tax_amount': 'tax_total',
        'gross_amount': 'gross_total',
    }

    return fieldSet[key]
}

const getCategoryTableConfig = (key) => {
    switch (key) {
        case 'Fees':
        case 'Supplier Invoices':
            return {
                'heading': ['Date', 'Description', 'Net', 'VAT', 'Gross'],
                'body': ['transaction_updated_date', 'description', 'net_amount', 'tax_amount', 'gross_amount']
            }
    
        case 'Payments':
            return {
                'heading': ['Date', 'Description', 'Paid'],
                'body': ['transaction_updated_date', 'description', 'gross_amount']
            }
        default:
            return {
                'heading': ['Date', 'Description', 'Received'],
                'body': ['transaction_updated_date', 'description', 'gross_amount']
            }
    }
}


const renderTable = (row, key) => {

    const tableConfig = getCategoryTableConfig(row.name)

    return (
        <>
            {row.records && (
                <>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            textAlign: "left",
                        }}
                    >
                        {row.name}
                    </Typography>
                    <Table
                        sx={{ width: "100%" }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    "&:last-child td, &:last-child th, td, th":
                                        {
                                            border: 0,
                                            paddingLeft:
                                                "5px",
                                            paddingRight:
                                                "5px",
                                        },
                                }}
                            >
                                {(tableConfig.heading.map((item, itemKey) => {
                                    let textAlign = "right";
                                    if (['Date', 'Description'].includes(item))
                                        textAlign = 'left'
                                    return (
                                        <TableCell align={textAlign} key={itemKey}>
                                            {item}
                                        </TableCell>
                                    )
                                }))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.records.map(
                                (
                                    record,
                                    key
                                ) => {

                                    return(
                                        <TableRow
                                            key={
                                                key
                                            }
                                            sx={{
                                                "&:last-child td, &:last-child th, td, th":
                                                    {
                                                        border: 0,
                                                        padding:
                                                            "5px",
                                                    },
                                            }}
                                        >

                                            {(tableConfig.body.map((field, itemKey) => {
                                                let textAlign = "right";
                                                if (['transaction_updated_date', 'description'].includes(field))
                                                    textAlign = 'left'
                                                let itemData = record[field]
                                                if (field.match(/amount/))
                                                    itemData = formatFlotPrice(record[field])
                                                if (field.match('date'))
                                                    itemData = displayData(record[field], 'date')
                                                return (
                                                    <TableCell align={textAlign} key={itemKey}>
                                                        {itemData}
                                                    </TableCell>
                                                )
                                            }))}
                                            {/* <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {displayData(
                                                    record.transaction_updated_date,
                                                    "date"
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    record.description
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatFlotPrice(
                                                    record.gross_amount
                                                )}
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            )}
                            <TableRow
                                key={key}
                                sx={{
                                    "&:last-child td, &:last-child th, td, th":
                                        {
                                            borderTop:
                                                "1px solid",
                                            padding:
                                                "5px",
                                        },
                                    "&:td.cell-total":
                                        {
                                            borderTop:
                                                "2px solid !important",
                                        },
                                }}
                            >
                                {(tableConfig.body.map((field, itemKey) => {
                                    let textAlign = "right";
                                    let itemData = ''
                                    if (field.match(/amount/)) {
                                        itemData = formatFlotPrice(
                                            addMinus(
                                                row[fieldToTotalField(field)],
                                                row.name
                                            )
                                        )
                                    }
                                    return (
                                        <TableCell align={textAlign} key={itemKey}>
                                            {itemData}
                                        </TableCell>
                                    )
                                }))}
                                {/* <TableCell></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell
                                    className="cell-total"
                                    align="right"
                                >
                                    {formatFlotPrice(
                                        addMinus(
                                            row.gross_total,
                                            row.name
                                        )
                                    )}
                                </TableCell> */}
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    )
}
const FaultIssuesContent = props => {
    const statementProps = props?.statements || {}
    const isMobile = props.mobile
    const [dateFilters, setDateFilters] = React.useState({
        property_id: props.property.crm_id,
    })
    const [apiValues, setApiValues] = React.useState({})
    const [landlordData, setLandlordData] = React.useState([])

    const handleChangeFromDate = val => {
        setDateFilters({ ...dateFilters, fromDate: rawDatePickerFormat(val) })
    }
    const handleChangeToDate = val => {
        setDateFilters({ ...dateFilters, toDate: rawDatePickerFormat(val) })
    }
    const handleSelect = val => {
        // if (val.target.value != "All") {
            setDateFilters({
                ...dateFilters,
                selected_property_id: val.target.value,
            })
        // }
        // if (val.target.value == "All") {
        //     setDateFilters({
        //         ...dateFilters,
        //         selected_property_id: false,
        //     })
        // }
    }

    const onSubmit = () => {
        setApiValues(dateFilters)
    }

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const fetchData = () => {
        if (!isEmpty(dateFilters.fromDate) && !isEmpty(dateFilters.toDate)) {
            setLoading(true)
            setData([])
            let dateFiltersAlt = {...dateFilters}
            if (dateFilters?.selected_property_id == 'All') {
                delete dateFiltersAlt['selected_property_id']
            }
            getTransactionStatement(dateFiltersAlt).then(res => {
                setLoading(false)
                setData(res.data)
            })
        }
    }

    React.useEffect(() => {
        if (!isEmpty(apiValues)) fetchData()

        if (isEmpty(landlordData)) {
            setLoading(true)
            getLandlordProperties(dateFilters).then(res => {
                setLoading(false)
                setLandlordData(res.data)
            })
        }
    }, [apiValues]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!landlordData?.propertyIds?.length) return <></>
    return (
        <>
            <br />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    p: 0,
                    m: 0,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                }}
            >
                <form>
                    <Grid container spacing={1.5}>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <FormDateField
                                required={true}
                                key={1}
                                classes={{ root: "globalForm-textField" }}
                                fullWidth
                                type="date"
                                label="From Date"
                                name="report_from"
                                id="report-from"
                                showPastDate={true}
                                handleOnChange={handleChangeFromDate}
                                inputProps={{
                                    defaultValue: dateFilters.fromDate,
                                }}
                                disableHighlightToday={true}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <FormDateField
                                required={true}
                                key={1}
                                classes={{ root: "globalForm-textField" }}
                                fullWidth
                                type="date"
                                label="To Date"
                                name="report_to"
                                id="report-to"
                                showPastDate={true}
                                handleOnChange={handleChangeToDate}
                                inputProps={{
                                    defaultValue: dateFilters.toDate,
                                }}
                                disableHighlightToday={true}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={4} lg={3}>
                            <SelectField
                                key={2}
                                className="globalForm-selectField" // I'm using className as this is applied to the correct parent div of select box
                                onChange={e => handleSelect(e)}
                                label={"Select Property"}
                                values={[
                                    ...(landlordData?.propertyIds || {}),
                                    {
                                        All: "All",
                                    }
                                ]}
                                sx={{
                                    width: "100%",
                                    "& .globalForm-selectField": {
                                        width: "100%",
                                        textAlign: "left",
                                    },
                                    "&.globalForm-selectField": {
                                        width: "100%",
                                        textAlign: "left",
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <Button variant="contained" onClick={onSubmit}>
                                Submit
                            </Button>
                            <Button variant="primary" onClick={handlePrint}>
                                <PrintIcon
                                    className={`print-icon`}
                                    sx={{ textAlign: "right" }}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {!!data.length && (
                        <>
                            <Grid
                                container
                                spacing={1}
                                ref={componentRef}
                                className="print-content"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className="print-brandinfo print-content"
                                >
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            style={{
                                                "padding-bottom": "50px",
                                                "padding-top": "50px",
                                            }}
                                        >
                                            <Grid item xs={6}>
                                                <Logo
                                                    type1={"logoSecondary"}
                                                    wrapper={false}
                                                    height={
                                                        statementProps.logo
                                                            ?.height
                                                    }
                                                    width={
                                                        statementProps.logo
                                                            ?.width
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    align="left"
                                                    fontSize={"14px"}
                                                    style={{
                                                        "padding-bottom":
                                                            "20px",
                                                    }}
                                                >
                                                    Estate Agent & Property
                                                    Managers
                                                    <br />
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            {landlordData.landlordOffice && (
                                                            <Typography
                                                                fontSize={
                                                                    "12px"
                                                                }
                                                            >
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: collectAddress(
                                                                            landlordData
                                                                                .landlordOffice
                                                                                .address
                                                                        ),
                                                                    }}
                                                                />
                                                            </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography
                                                                fontSize={
                                                                    "12px"
                                                                }
                                                            >
                                                                {
                                                                    statementProps.email
                                                                }
                                                                <br />
                                                                {
                                                                    FRONTEND_SITE_URL
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                        <Grid item xs={6}>
                                            {landlordData.contact && (
                                                <Typography fontSize={"14px"}>
                                                    {
                                                        landlordData.contact
                                                            .forename
                                                    }{" "}
                                                    {
                                                        landlordData.contact
                                                            .surname
                                                    }
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: collectAddress(
                                                                landlordData
                                                                    .contact
                                                                    .primary_address
                                                            ),
                                                        }}
                                                    />
                                                </Typography>
                                            )}
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography
                                                    align="right"
                                                    fontSize={"14px"}
                                                >
                                                    Date:
                                                    {reportDate(new Date())}
                                                    <br />
                                                    Reference:{" "}
                                                    {landlordData.landlordId}
                                                    <br />
                                                    VAT Number:
                                                    {statementProps.vatNumber}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            margin: "auto",
                                            "padding-top": "50px",
                                        }}
                                    >
                                        <Typography align="center">
                                            Landlord Income & Expenditure for{" "}
                                            {reportDate(dateFilters.fromDate)}{" "}
                                            to {reportDate(dateFilters.toDate)}{" "}
                                            -{" "}
                                            {collectPropertyAddress(
                                               landlordData?.propertyIds, dateFilters?.selected_property_id
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {/* <header className="print-header print-content"> </header> */}

                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        margin: "auto",
                                        "padding-bottom": "50px",
                                        "padding-top": "50px",
                                    }}
                                >
                                    <TableContainer>
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            Summary
                                        </Typography>
                                        <Table
                                            sx={{ width: "100%" }}
                                            aria-label="simple table"
                                        >
                                            <TableBody>
                                                {data.map((row, key) => (
                                                    <TableRow
                                                        key={key}
                                                        sx={{
                                                            "&:last-child td, &:last-child th, td, th":
                                                                {
                                                                    border: 0,
                                                                    padding:
                                                                        "5px",
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.gross_total
                                                                ? formatFlotPrice(
                                                                      addMinus(
                                                                          row.gross_total,
                                                                          row.name
                                                                      )
                                                                  )
                                                                : formatFlotPrice(row.gross_total)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    {data.map((row, key) => (
                                        <TableContainer>
                                            {renderTable(row, key)}
                                            {/* {row.records && (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {row.name}
                                                    </Typography>
                                                    <Table
                                                        sx={{ width: "100%" }}
                                                        aria-label="simple table"
                                                    >
                                                        <TableHead>
                                                            <TableRow
                                                                sx={{
                                                                    "&:last-child td, &:last-child th, td, th":
                                                                        {
                                                                            border: 0,
                                                                            paddingLeft:
                                                                                "5px",
                                                                            paddingRight:
                                                                                "5px",
                                                                        },
                                                                }}
                                                            >
                                                                <TableCell align="left">
                                                                    Date
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    Description
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    Received
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {row.records.map(
                                                                (
                                                                    record,
                                                                    key
                                                                ) => (
                                                                    <TableRow
                                                                        key={
                                                                            key
                                                                        }
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th, td, th":
                                                                                {
                                                                                    border: 0,
                                                                                    padding:
                                                                                        "5px",
                                                                                },
                                                                        }}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                        >
                                                                            {displayData(
                                                                                record.transaction_updated_date,
                                                                                "date"
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {
                                                                                record.description
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {formatFlotPrice(
                                                                                record.gross_amount
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                            <TableRow
                                                                key={key}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th, td, th":
                                                                        {
                                                                            borderTop:
                                                                                "1px solid",
                                                                            padding:
                                                                                "5px",
                                                                        },
                                                                    "&:td.cell-total":
                                                                        {
                                                                            borderTop:
                                                                                "2px solid !important",
                                                                        },
                                                                }}
                                                            >
                                                                <TableCell></TableCell>
                                                                <TableCell align="left"></TableCell>
                                                                <TableCell
                                                                    className="cell-total"
                                                                    align="right"
                                                                >
                                                                    {formatFlotPrice(
                                                                        addMinus(
                                                                            row.gross_total,
                                                                            row.name
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )} */}
                                        </TableContainer>
                                    ))}
                                </Grid>
                                {/* <footer className="print-footer print-content">
                    Head O ce: 70 St. Mary Axe, London, EC3A 8BE Company
                    Registra on Number 08761192
                </footer> */}
                            </Grid>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default FaultIssuesContent
