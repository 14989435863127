import React from "react"
import _ from "lodash"
import NoActions from "../../../../progress/NoActions"
// import Actions from '../../../../actions';
import Grid from "@mui/material/Grid"
import Payments from "../../../../payments"
import Tabs from "../../../../tabs"
import SingleProperty from "../../../../property/singleProperty"
import _properties from "../../../../../../sample/properties.yaml"
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import Stack from "@mui/material/Stack"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import { ComponentWithPagination } from "../../../../pagination"
import TransactionReport from "./../transactionReport"
import { useGetPropertyTransactionsQuery } from "./../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"

const PaymentsComponent = props => {
    return <Payments {...props} />
}
const transactionsTabData = ({ data, property, landlord, ...rest }) => {
    const tabData = []
    let count = 0
    Object.entries(data.tabs).map(([key, value]) => {
        count++
        return tabData.push({
            id: `tab${count}`,
            name: `${key}`,
            content: (
                <ComponentWithPagination
                    component={PaymentsComponent}
                    marginTop={20}
                    data={value.items}
                    totalOutstandingAmount={value.total_outstanding_amount}
                />
            ),
        })
    })

    if (landlord?.canDownloadStatement) {
        tabData.push({
            id: `tab${count + 1}`,
            name: `Income & Expenditure`,
            content: <TransactionReport {...rest} property={property} />,
        })
    }

    return tabData
}
const TabsTransactionsComp = props => {
    return <Tabs data={transactionsTabData(props)} fullWidthTabs={true} />
}

const SinglePropertyComp = props => {
    let offerText = ""
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`
    }

    let property = _properties[0] // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText,
        }
    }

    return (
        <>
            <SingleProperty properties={[property]} />
        </>
    )
}

const PaymentsContent = props => {
    const isMobile = props.mobile

    const authUser = isAuthenticated()

    const {
        data: propertyTransactions,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyTransactionsQuery(
        { property_id: props.property.property_detail.crm_id },
        { skip: !authUser }
    )

    const offerData = propertyTransactions || []

    const noRecord = !isLoading && _.isEmpty(offerData?.tabs) // @todo: Need to check the data

    if (isLoading) {
        return (
            <Grid container>
                <Grid item md={8}>
                    <Stack direction="column" spacing={4}>
                        <TabContainerSkeleton noBorder>
                            <TabSkeleton />
                            <TabSkeleton />
                        </TabContainerSkeleton>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={4}>
                        <AutoHeightSkeleton
                            width="100"
                            aspectRatio={6 / 19}
                            variant="rectangular"
                        />
                        <BodyLargeSkeleton width="50%" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    if (noRecord) {
        return (
            <NoActions
                title="There are no Transactions"
                icon="transactionsIcon"
            />
        )
    }

    if (isMobile) {
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TabsTransactionsComp
                        {...props}
                        data={offerData}
                        property={props.property?.property_detail}
                    />
                </Grid>
                {/*<Grid item xs={12}>
                    <MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />
                </Grid>*/}
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TabsTransactionsComp
                                {...props}
                                data={offerData}
                                property={props.property?.property_detail}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default PaymentsContent
