import React from "react"
import { get } from "lodash"
import NoActions from "../../../../progress/NoActions"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import MakeOfferBtn from "../../../../property/components/makeOffer"
import ListItem from "./list"
import {
    useGetPropertyOffersQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"

const Content = props => {
    const authUser = isAuthenticated()
    const {
        data: propertyOffers,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyOffersQuery(
        { property_id: props.property.property_detail.crm_id },
        { skip: !authUser }
    )
    const offerData = propertyOffers || []
    const noRecord = !isLoading && !offerData.length
    const propertyImage = props.property.property_detail?.thumbnail
    const actionTitle = props?.offer?.actionTitle || "Offers"
    const listDisclaimer = props?.offer?.listDisclaimer || ""
    const showOfferBtn = get(
        props,
        `${props.role || ""}.tabOfferOfferBtn`,
        false
    )
    const showAddIcon = get(props, `${props.role || ""}.showAddIcon`, false)

    const reloadDataFun = React.useCallback(() => {
        if (props?.role && !["tenant", "buyer"].includes(props?.role))
            store.dispatch(
                propertyApi.util.invalidateTags(["PendingActionCount"])
            )
        store.dispatch(propertyApi.util.invalidateTags(["PropertyOffers"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !offerData.length) {
        return <ActionsSkeletonWithMore title />
    }

    return (
        <>
            {showOfferBtn && (
                <MakeOfferBtn
                    hasOffer={!!offerData.length}
                    addIcon={true}
                    showAddIcon={showAddIcon}
                    property={props.property.property_detail}
                    reloadDataFun={reloadDataFun}
                />
            )}

            <ListItem
                {...props}
                noRecord={noRecord}
                data={offerData}
                image={propertyImage}
                title={`${actionTitle}`}
                listDisclaimer={listDisclaimer}
                reloadDataFun={reloadDataFun}
                property={props.property}
            />
        </>
    )
}

export default Content
