import React from "react"
import Grid from "@mui/material/Grid"
import NoActions from "../../../../progress/NoActions"
import Tabs from "../../../../tabs"
import FloatingContainer from "../../../../floatingContainer"
import ContactBtn from "../../../../property/components/contactBtn"
import ReportFaultBtn from "../../../../property/components/reportFaultBtn"
import SingleProperty from "../../../../property/singleProperty"
import _properties from "../../../../../../sample/properties.yaml"
import Stack from "@mui/material/Stack"
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import ListItem from "./list"
import {
    useGetPropertyWorksorderQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"

const SinglePropertyComp = props => {
    let offerText = ""
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`
    }

    let property = _properties[0] // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText,
        }
    }

    return (
        <>
            <SingleProperty properties={[property]} />
        </>
    )
}

const ReportFaultBtnComp = props => {
    const reloadDataFun = React.useCallback(() => {
        store.dispatch(propertyApi.util.invalidateTags(["PropertyWorksorder"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <ReportFaultBtn {...props} reloadDataFun={reloadDataFun} />
}

const FloatingContainerComp = props => {
    return (
        <FloatingContainer>
            <ContactBtn />
            {props?.current_tenancy_id && !props.is_owner && (
                <ReportFaultBtnComp btnLabel="Report" {...props} />
            )}
        </FloatingContainer>
    )
}

const RecentActivites = ({ data, ...rest }) => {
    const noRecord = !data.length

    return <ListItem {...rest} noRecord={noRecord} data={data} />
}

const ActionsComp = ({ data, ...rest }) => {
    const noRecord = !data.length
    const propertyImage = rest?.property?.thumbnail

    return (
        <ListItem
            {...rest}
            data={data}
            noRecord={noRecord}
            image={propertyImage}
        />
    )
    // return (
    //     <>
    //         {noRecord && (
    //             <NoActions
    //                 title="There are no open items"
    //                 icon="faultsIcon"
    //             />
    //         )}
    //         {!noRecord && (
    //             <Actions {...rest} data={data} image={propertyImage} />
    //         )}
    //     </>
    // )
}

const faultTabData = props => {
    const open_items = props?.data?.open_items || []
    const closed_items = props?.data?.closed_items || []
    return [
        {
            id: "tab1",
            name: "Open",
            content: <ActionsComp {...props} data={open_items} />,
        },
        {
            id: "tab2",
            name: "Closed",
            content: <RecentActivites data={closed_items} />,
        },
    ]
}
const TabsFaultComp = props => {
    return <Tabs data={faultTabData(props)} fullWidthTabs={true} />
}
const FaultIssuesContent = props => {
    const isMobile = props.mobile

    const authUser = isAuthenticated()

    const {
        data: worksOrders,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyWorksorderQuery(
        { property_id: props.property.property_detail.crm_id },
        { skip: !authUser }
    )

    const offerData = worksOrders || []
    const noRecord = !isLoading && !offerData.length

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(propertyApi.util.invalidateTags(["PropertyWorksorder"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !offerData.length) {
        return (
            <Grid container>
                <Grid item md={8}>
                    <TabContainerSkeleton noBorder>
                        <TabSkeleton />
                        <TabSkeleton />
                    </TabContainerSkeleton>
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={4}>
                        <AutoHeightSkeleton
                            width="100%"
                            aspectRatio={6 / 19}
                            variant="rectangular"
                        />
                        <BodyLargeSkeleton width="50%" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    if (isMobile) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <TabsFaultComp
                        data={offerData}
                        property={props.property?.property_detail}
                        reloadDataFun={reloadDataFun}
                    />
                    <FloatingContainerComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                        current_tenancy_id={props.property?.current_tenancy_id}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <TabsFaultComp
                        data={offerData}
                        property={props.property?.property_detail}
                        reloadDataFun={reloadDataFun}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                        current_tenancy_id={props.property?.current_tenancy_id}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default FaultIssuesContent
