import React, { memo } from "react"
import NoActions from "../../../../progress/NoActions"
import Action from "../../../../actions/action/offer"
import { FormStoreProvider } from "../../../../../../services"
import ComponentLayout from "../../../../ComponentLayout"
import { ComponentWithPagination } from "../../../../pagination"

const ListItem = ({ data, image, ...rest }) => {
    return (
        <>
            {data.map((item, key) => {
                return (
                    <div id={item.crm_id || key} key={item.crm_id || key}>
                        <FormStoreProvider>
                            <Action
                                data={{ ...item, image: image }}
                                {...rest}
                            />
                        </FormStoreProvider>
                    </div>
                )
            })}
        </>
    )
}

const List = ({ noRecord, data, title, listDisclaimer, ...rest }) => {
    const noResult = noRecord || !data.length

    const noItemMsg = rest?.offer?.noOfferMessage || `There are no ${title}`

    return (
        <>
            {noResult && <NoActions title={noItemMsg} icon="offerIcon" />}
            {!noResult && (
                <>
                    <ComponentLayout
                        // title={`${title} (${data.length})`}
                        title={
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${title} (${data.length}) ${listDisclaimer}`,
                                }}
                            />
                        }
                    >
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            // useLoadMore={false}
                            // pageSize={1}
                        />
                    </ComponentLayout>
                </>
            )}
        </>
    )
}

export default memo(List)
