import React, { useState } from "react"
import ComponentLayout from "../../../../ComponentLayout"
import NewDocumentBtn from "../../../../property/components/newDocument"
import ActionSkeletonWithMore from "../../../../actions/actionSkeletonWithMore"
import { canShowNewDocumentBtn } from "../../../../property/components/newDocument/utils"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputAdornment from "@mui/material/InputAdornment"
import AccountCircle from "@mui/icons-material/Search"
import ListItem from "./list"
import { useGetPropertyDocumentsQuery } from "./../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"

const Content = props => {
    const authUser = isAuthenticated()
    const {
        data: documents,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyDocumentsQuery(
        {
            property_id: props.property.property_detail.crm_id,
            document_section:
                props.type === "lettings" || props.type === "renting"
                    ? "tenancydocuments"
                    : "propertydocuments",
        },
        { skip: !authUser }
    )

    const [filter, setFilter] = useState({
        query: "",
        type: false,
    })

    const data = documents || []

    const noRecord = !isLoading && !data.length
    const propertyImage = props.property.property_detail?.thumbnail
    const handleChange = (type, e) => {
        setFilter({
            ...filter,
            [type]: e.target.value,
        })
    }

    const collectDocTypes = data => {
        return [...new Set(data.map(item => item.type))]
    }

    return (
        <>
            <div>
                {!!data.length && data.length > 5 && (
                    <>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                placeholder="Type document name..."
                                type="search"
                                defaultValue={filter.query}
                                onChange={e => handleChange("query", e)}
                                label={`Filter by document name`}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-select-small">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Type"
                                defaultValue=""
                                onChange={e => handleChange("type", e)}
                            >
                                <MenuItem value={false}>{`All type`}</MenuItem>
                                {collectDocTypes(data).map(t => {
                                    return <MenuItem value={t}>{t}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </>
                )}
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    {canShowNewDocumentBtn({
                        ...props.property,
                        role: props?.role,
                    }) && <NewDocumentBtn property={props.property} />}
                </FormControl>
            </div>

            {isLoading && !data.length ? (
                <ActionSkeletonWithMore title />
            ) : (
                <ListItem
                    noRecord={noRecord}
                    data={data}
                    propertyImage={propertyImage}
                    title={`Documents`}
                    filter={filter}
                />
            )}
        </>
    )
}

const ContentWrap = props => {
    return (
        <ComponentLayout>
            <Content {...props} />
        </ComponentLayout>
    )
}

export default ContentWrap
