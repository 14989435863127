import React from "react"
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
import ListItem from "./list"
import {
    useGetPropertyAppointmentsQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"

// Calendar Action Container wrap for enable loading
const AppointmentsContent = props => {
    const authUser = isAuthenticated()

    const {
        data: propertyAppointments,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyAppointmentsQuery(
        { property_id: props.property.property_detail.crm_id },
        { skip: !authUser }
    )
    const data = propertyAppointments || []
    const noRecord = !isLoading && !data.length
    const propertyImage = props.property.property_detail?.thumbnail

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                "PendingActionCount",
                "PropertyAppointment",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !data.length) {
        return <ActionsSkeleton title />
    }

    return (
        <ListItem
            title={`Appointments`}
            data={data}
            property={props.property.property_detail}
            reloadDataFun={reloadDataFun}
            image={propertyImage}
            noRecord={noRecord}
        />
    )
}

export default React.memo(AppointmentsContent)
